<template>
  <div class="page">
    <div class="page-content">
      <div class="login-container">
        <SVGSpinner class="spinner" />
      </div>
    </div>
  </div>
</template>

<script>
import SVGSpinner from '@/assets/icons/spinner.svg'

export default {
  name: 'AuthConfirm',
  components: {
    SVGSpinner
  },
  created () {
    this.login()
  },
  methods: {
    async login () {
      try {
        await this.$store.dispatch('auth/login', { code: this.code })
        this.$router.push({ name: 'ExercisesList' })
      } catch (e) {
        console.error(e)
        this.$router.push({ name: 'Login' })
      }
    }
  },
  computed: {
    code () {
      const parsedHash = new URLSearchParams(this.$route.hash.substr(1))
      const code = parsedHash.get('code')
      window.location.replace('#') //Removes URL from browser history 
      return code
    }
  }
}
</script>

<style lang="scss" scoped>
.page{
  background:
    linear-gradient(132deg, #091B62 5%, rgba(12, 36, 134, 0) 96.16%),
    linear-gradient(0deg, #0D229C, #0D229C);
  height: 100vh;
  color: $white-color;

  .page-content{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg.spinner{
      width: 100px;
      height: 100px;
    }
  }
}
</style>
